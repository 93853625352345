.add-external-worker-wrapper {
  max-width: 100%;
  margin: auto;
  padding-bottom: 50px;
  background: #f2f2f2;
}
.ant-row.add-external-worker-wrapper .ant-col.ant-col-20 {
  margin: auto;
}
.middle-color {
  background: #fff;
  padding: 20px;
}
p.general-info {
  font-size: 20px;
  font-weight: 500;
  line-height: 30px;
  color: #205214;
  padding-bottom: 40px;
  border-bottom: 3px solid #f2f2f2;
}
h1.worker-heading {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 15px;
}
.add-external-worker-wrapper form label {
  width: fit-content;
  margin-top: 25px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.add-external-worker-wrapper form input {
  max-width: 522.94px;
  background: #f6f6f6;
  border: 1px solid #f2f2f2;
  padding: 9px;
  border-radius: 8px;
}
.button-wrapper .ant-form-item-control {
  margin: 0;
}
.button-wrapper .ant-form-item-control button {
  width: 40px;
  height: 40px;
  padding: 0;
  border-radius: 5px;
}
.update-external .button-wrapper .ant-form-item-control button {
  float: right;
  width: 100%;
}
.button-wrapper .ant-form-item-control button span{
  font-size: 20px;
  line-height: 1;
}
.update-external .button-wrapper .ant-form-item-control button span{
  font-size: unset;
  line-height: 1;
}
.button-wrapper .ant-form-item-control button + button {
  float: right;
  min-width: 150px;
  min-height: 44px;
  font-size: 20px;
  border-radius: 5px;
}
form .button-wrapper {
  margin-bottom: 0;
}
.add-external-worker-wrapper .ant-row.ant-form-item {
  margin-bottom: 20px;
}

.ant-form-item-control-input {
  max-width: 522.94px;
 }

 .phone-input .ant-input-affix-wrapper {
  background: #f6f6f6;
  border-radius: 8px;
  border: none;
 }

 .phone-input .ant-input-affix-wrapper input {
  min-height: 32px;
 }