$color_1: #205214;
$color_2: #6d6d6d;
$color_3: black;
$color_4: #357ffa;
$color_5: red;
$background-color_1: #eaeaea;
$background-color_2: orange;

form#workersFilter {
  display: flex;
}
.construction-sites-details-wrapper {
  background: #f2f2f2;
  margin: auto;
  padding: 10px 15px;
  h1 {
    font-size: 50px;
  }
}
.ant-row.construction-sites-row {
  tbody.ant-table-tbody {
    tr {
      td {
        &:last-child {
          text-align: center;
        }
      }
    }
  }
  thead.ant-table-thead {
    tr {
      th {
        &:last-child {
          text-align: center;
        }
      }
    }
  }
}
.general-info-wrapper {
  padding: 25px;
  max-width: 720px;
  margin: 0 auto 25px;
  background: #fff;
  h4 {
    color: $color_1;
    font-size: 20px;
    line-height: 30px;
  }
}
.general-info-paragraph {
  p {
    font-size: 15px;
    line-height: 18px;
    color: $color_2;
    span {
      font-size: 16px;
      line-height: 30px;
      font-weight: 500;
      color: $color_3;
      margin-right: 10px;
    }
  }
}
.worker-info-wrapper {
  padding: 25px;
  background: #fff;
  max-width: 720px;
  margin: auto;
  h4 {
    color: $color_1;
    font-size: 20px;
    line-height: 30px;
  }
  .ant-space.ant-space-vertical {
    width: 100%;
  }
  .worker-information {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  form {
    .ant-form-item {
      .ant-picker {
        min-height: 44px;
        max-width: 136.05px;
        margin: 0 12px;
        border-radius: 5px;
        background: #f2f2f2;
      }
      .ant-select-selector {
        min-height: 44px;
        max-width: 136.05px;
        margin: 0 12px;
        border-radius: 5px;
        background: #f2f2f2;
        width: 136.05px;
        margin-right: 24px;
      }
    }
    .ant-select-selector {
      .ant-select-selection-item {
        display: flex;
        align-items: center;
      }
    }
    .ant-select {
      span.ant-select-arrow {
        right: 30px;
      }
    }
  }
  table {
    thead {
      th {
        font-size: 16px;
        line-height: 30px;
        padding: 10px;
      }
    }
    tbody {
      td {
        padding: 14px;
      }
    }
  }
  .anticon-eye {
    svg {
      color: $color_4;
      width: 23.33px;
      height: 18.15px;
    }
  }
  .ant-progress-inner {
    .ant-progress-bg {
      border-radius: inherit;
      padding: 5px;
    }
    border-radius: initial;
    box-shadow: 0 0 0 0.2pt;
  }
}
.construction-sites-row {
  .ant-row.ant-form-item {
    margin: 0;
  }
  background: #f2f2f2;
  padding-bottom: 50px;
  .ant-col-20 {
    margin: auto;
  }
  h1 {
    font-size: 50px;
    margin-top: 15px;
    margin-bottom: 20px;
  }
}
.ant-space.ant-space-horizontal.ant-space-align-center.worker-filter {
  margin-top: 20px;
}
.worker-filter {
  p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 500;
    margin: 0;
  }
  button {
    span {
      font-size: 15px;
      line-height: 18px;
    }
    width: 90px;
    height: 44px;
    border-radius: 5px;

    &.ant-btn-default {
      margin-left: 10px;
    }
  }
}
.worker-information {
  button {
    span {
      font-size: 15px;
      line-height: 18px;
    }
    width: 150px;
    height: 44px;
    border-radius: 5px;
  }
}
.toolboxes-info-wrapper {
  padding: 25px;
  max-width: 550px;
  margin: auto;
  background: #fff;
  h4 {
    color: $color_1;
    font-size: 20px;
    line-height: 30px;
  }
  table {
    tbody {
      td {
        font-size: 15px;
        line-height: 30px;
        padding: 10px;
      }
    }
    thead {
      th {
        font-size: 16px;
        line-height: 30px;
        padding: 10px;
      }
    }
  }
  .anticon-eye {
    svg {
      color: $color_4;
      width: 23.33px;
      height: 18.15px;
    }
  }
}
.toolboxes-info-heading {
  display: flex;
  justify-content: space-between;
  align-items: center;
  button {
    span {
      font-size: 30px;
      line-height: 39px;
    }
    width: 40px;
    height: 40px;
    padding: 0;
  }
}
thead.ant-table-thead {
  tr {
    th {
      background-color: $background-color_1;
    }
  }
}
.construction-sites-col {
  background: #fff;
  padding: 25px;
  h5 {
    color: $color_1;
    font-size: 20px;
    line-height: 30px;
  }
}
.construction-sites-table {
  table {
    thead {
      th {
        font-size: 16px;
        line-height: 30px;
        padding: 10px;
      }
    }
    tbody {
      td {
        font-size: 15px;
        line-height: 30px;
        padding: 10px;
      }
    }
  }
  .ant-progress-inner {
    .ant-progress-bg {
      border-radius: inherit;
      padding: 5px;
    }
    border-radius: inherit;
    box-shadow: 0 0 0 0.2pt;
  }
  .anticon-eye {
    svg {
      color: $color_4;
      width: 23.33px;
      height: 18.15px;
    }
  }
}
.ant-progress.ant-progress-line.ant-progress-status-normal.ant-progress-show-info.ant-progress-small {
  .ant-progress-bg {
    background-color: $background-color_2;
  }
}
.eye-bin-wrapper {
  .bin {
    color: $color_5;
  }
}
@media only screen and (max-width: 1200px) and (min-width: 768px) {
  .construction-sites-details-wrapper {
    h1 {
      font-size: 42px;
      text-align: center;
    }
    .ant-row {
      flex-direction: column;
    }
    .general-info-ipad {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .toolboxes-info-wrapper {
    margin-top: 25px;
  }
  .ant-col-20 {
    .construction-sites-row {
      h1 {
        font-size: 40px;
        margin-top: 11px;
        margin-bottom: 15px;
      }
    }
  }
}
@media only screen and (max-width: 767px) {
  .general-info-wrapper {
    padding: 15px;
  }
  .worker-info-wrapper {
    padding: 15px;
    form {
      .ant-form-item {
        .ant-select-selector {
          width: auto;
          margin-right: 15px;
        }
      }
    }
  }
  .toolboxes-info-wrapper {
    padding: 15px;
    margin-top: 20px;
  }
  .construction-sites-details-wrapper {
    h1 {
      font-size: 34px;
      text-align: center;
    }
    .ant-row {
      flex-direction: column;
    }
    .general-info-ipad {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .construction-sites-row {
    h1 {
      font-size: 40px;
      margin-top: 11px;
      margin-bottom: 15px;
    }
  }
  .construction-sites-col {
    padding: 15px;
  }
  .construction-sites-table {
    table {
      thead {
        th {
          font-size: 15px;
          line-height: 22px;
          padding: 10px 9px;
        }
      }
      tbody {
        td {
          font-size: 14px;
          line-height: 22px;
          padding: 10px 9px;
        }
      }
    }
  }
}
@media only screen and (max-width: 492px) {
  form#workersFilter {
    display: table;
  }
  .general-info-wrapper {
    padding: 8px;
  }
  .worker-info-wrapper {
    padding: 8px;
    form {
      .ant-form-item {
        .ant-picker {
          margin: 5px 0;
        }
        .ant-select-selector {
          margin: 5px 0;
        }
      }
    }
    .worker-table {
      th {
        font-size: 14px;
        padding: 0;
        line-height: 20px;
        padding: 4px;
      }
      td {
        font-size: 13px;
        padding: 7px 5px;
      }
    }
    .ant-progress-inner {
      .ant-progress-bg {
        padding: 4px;
      }
    }
  }
  .toolboxes-info-wrapper {
    padding: 8px;
  }
  .construction-sites-details-wrapper {
    .general-info-ipad {
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
      display: block;
      flex: 0 0 100%;
      max-width: 100%;
    }
  }
  .worker-information {
    button {
      width: 120px;
      height: 40px;
    }
  }
  .ant-space.ant-space-horizontal.ant-space-align-center.worker-filter {
    flex-direction: column;
    align-items: flex-start;
  }
  .worker-filter {
    button {
      width: 136.05px;
    }
  }
  .construction-sites-row {
    h1 {
      font-size: 35px;
      margin-top: 5px;
      margin-bottom: 8px;
    }
  }
  .construction-sites-col {
    h5 {
      font-size: 19px;
      line-height: 25px;
    }
    padding: 7px;
  }
  .construction-sites-table {
    table {
      thead {
        th {
          font-size: 13px;
          line-height: 20px;
          padding: 10px 4px;
        }
      }
      tbody {
        td {
          font-size: 12px;
          line-height: 20px;
          padding: 10px 4px;
          max-width: 75px;
        }
      }
    }
  }
}
