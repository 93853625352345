.homepage-banner {
    img {
        object-fit: cover;
    }
}


.ant-col.homepage-banner.ant-col-xs-24.ant-col-sm-24.ant-col-md-24.ant-col-lg-12 img {
    width: 100%;
    height: 89vh;
}

.ant-col.form-wrapper.ant-col-xs-24.ant-col-sm-24.ant-col-md-24.ant-col-lg-12 {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.form-wrapper h1 {
    margin-bottom: 0px;
}

.form-wrapper label.ant-form-item-required {
    font-weight: 700;
    padding: 30px 0 0 0;
}

.form-wrapper button.ant-btn.ant-btn-primary.ant-btn-block {
    margin: 64px 0 16px 0;
}

.forgot-password a{
    color: #000;
}

.forgot-password a:hover{
    color: #000;
}