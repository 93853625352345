@import '~antd/dist/antd.css';

.ant-spin.ant-spin-lg.ant-spin-spinning {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88vh;
}

@media only screen and (max-width: 950px) {
    .ant-modal-centered {
        .ant-modal-content {
            width: 800px;
            .ant-modal-body {
                #pdf-container-view, #pdf-container {
                    .canvas-container {
                        width: 700px !important;
                        height: 990.36px !important;
                        .lower-canvas {
                            width: 700px !important;
                            height: 990.36px !important;
                        }
                        .upper-canvas {
                            width: 700px !important;
                            height: 990.36px !important;
                        }
                    }
                }
            }
        }
    }
}


@media only screen and (max-width: 830px) {
    .ant-modal-centered {
        .ant-modal-content {
            width: 650px;
            .ant-modal-body {
                #pdf-container-view, #pdf-container {
                    .canvas-container {
                        width: 600px !important;
                        height: 848.88px !important;
                        .lower-canvas {
                            width: 600px !important;
                            height: 848.88px !important;
                        }
                        .upper-canvas {
                            width: 600px !important;
                            height: 848.88px !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 670px) {
    .ant-modal-centered {
        .ant-modal-content {
            width: 550px;
            .ant-modal-body {
                #pdf-container-view, #pdf-container {
                    .canvas-container {
                        width: 500px !important;
                        height: 707.40px !important;
                        .lower-canvas {
                            width: 500px !important;
                            height: 707.40px !important;
                        }
                        .upper-canvas {
                            width: 500px !important;
                            height: 707.40px !important;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 570px) {
    .ant-modal-centered {
        .ant-modal-content {
            width: 450px;
            .ant-modal-body {
                #pdf-container-view, #pdf-container {
                    .canvas-container {
                        width: 400px !important;
                        height: 565.92px !important;
                        .lower-canvas {
                            width: 400px !important;
                            height: 565.92px !important;
                        }
                        .upper-canvas {
                            width: 400px !important;
                            height: 565.92px !important;
                        }
                    }
                }
            }
        }
    }
}

