/* .ant-row.add-toolbox-wrapper {
  justify-content: center;
  align-items: center;
  height: 89vh;
} */
.add-toolbox-wrapper h1.toolbox-heading {
  font-size: 50px;
  font-weight: 500;
  margin-bottom: 20px;
  margin-top: 15px;
}
.add-toolbox-wrapper {
  background: #f2f2f2;
  padding-bottom: 50px;
}
.add-toolbox-wrapper .ant-col.ant-col-20 {
  margin: auto;
}
.add-toolbox-wrapper .toolbox-column {
  margin: auto;
}
.add-toolbox-wrapper form .form-background-color {
  background: #fff;
  padding: 25px;
  margin-bottom: 30px;
}
.add-toolbox-wrapper form input {
  max-width: 650px;
  max-height: 45px;
  background: #f6f6f6;
  border: 1px solid #f2f2f2;
  padding: 9px;
  border-radius: 5px;
}
.add-toolbox-wrapper form label {
  width: fit-content;
  margin-top: 30px;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 10px;
}
.add-toolbox-wrapper form .input-date {
  width: fit-content;
  display: inline-block;
  margin-right: 25px;
}
.add-toolbox-wrapper form .input-date .ant-picker {
  min-width: 200px;
  background: #f6f6f6;
  border-radius: 5px;
  padding: 0;
  padding: 0 5px;
}
.file-wrapper .ant-form-item-label label {
  font-size: 20px;
  padding-bottom: 15px;
}
/* .add-toolbox-wrapper form .file-language-add-btn {
  display: inline-block;
  vertical-align: bottom;
} */
.upload-lang-wrapper {
  display: flex;
  /* align-items: center; */
  position: relative;
}
.add-toolbox-wrapper .ant-row.ant-form-item {
  margin-top: 20px !important;
}
.file-language-add-btn .ant-upload button {
  min-width: 310px;
  min-height: 44px;
  background: #f6f6f6;
  margin-right: 50px;
}

.file-language-add-btn.language .ant-select-selector {
  min-height: 44px;
  align-items: center;
}
.ant-row.ant-form-item.file-language-add-btn.language {
  display: inline-flex;
  flex-direction: inherit;
  align-items: center;
  margin-right: 50px;
  position: relative;
  top: 26px;
}
.file-language-add-btn.language .ant-form-item-label {
  margin-right: 35px;
}
.file-language-add-btn.language .ant-form-item-control div {
  max-width: 135px;
  background: #f6f6f6;
  border-radius: 5px;
}
.file-language-add-btn .ant-form-item-control {
  margin-left: 0;
}
form .plus-btn {
  min-height: 40px;
  min-width: 40px;
  padding: 0;
}
form .plus-btn span {
  font-size: 30px;
  line-height: 0.8;
}
.toolbox-save-btn {
  float: right;
  margin-right: 25px;
}
/* .toolbox-save-btn,
.add-save-btn {
  min-width: 155px;
  min-height: 44px;
} */
.add-toolbox-wrapper form button {
  border-radius: 5px;
  font-size: 16px;
  /* margin-top: 20px; */
  font-weight: bold;
}
.ant-spin.ant-spin-spinning.isSubmitted {
  width: 100%;
  height: 100%;
  background: rgba(224, 229, 238, 0.8);
  position: absolute;
  z-index: 999;
  display: flex;
  align-items: center;
  justify-content: center;
}
@media only screen and (min-width: 0px) and (max-width: 520px) {
  .add-toolbox-wrapper h1.toolbox-heading {
    font-size: 32px !important;
  }
}
