$color_1: black;
$color_2: initial;

.ant-row.header-wrapper {
  padding: 20px 0;
}
.header-wrapper {

  .ant-col:first-child {
    display: flex;
    align-items: center;
    .logo {
      max-width: 100%;   
    }
  }
  .ant-col.ant-col-4 {
    border-right: 1px solid gray;
  }
  .ant-menu-horizontal {
    border-bottom: none;
    > .ant-menu-submenu {
      &::after {
        bottom: -8px;
      }
    }
    &:not(.ant-menu-dark) {
      > .ant-menu-item-selected {
        &::after {
          border: none;
        }
      }
      > .ant-menu-submenu-selected {
        &::after {
          border: none;
        }
      }
      > .ant-menu-item {
        &:hover {
          &::after {
            border-bottom: unset;
          }
        }
      }
    }
  }
  span.ant-avatar.ant-avatar-circle.ant-avatar-image.ant-menu-item-icon {
    img {
      width: 50px !important;
      height: 50px !important;
    }
  }
  .ant-avatar {
    overflow: unset;
  }
}
a.active {
  font-weight: bold;
  color: $color_1 !important;
}
.ant-menu-item {
  font-size: 16px;
  text-transform: uppercase;
}
.ant-menu-item-selected {
  .ant-menu-title-content {
    a {
      color: $color_2 !important;
    }
  }
}
.profile-wrapper {
  @media only screen and (max-width: 1200px) {
    display: flex !important;
    justify-content: flex-end;
  }
  @media only screen and (min-width: 0px) and (max-width: 767px) {
    h1.worker-heading {
      font-size: 32px !important;
      font-weight: 700 !important;
    }
  }
}
