$background-color_1: #f2f2f2;
$background-color_2: #fff;

.worker-details-wrapper {
  background-color: $background-color_1;
  padding: 25px 0 50px 0;
}
.worker-info {
  padding: 32px;
  background-color: $background-color_2;
  padding: 10px;
  .edit-btn {
    button {
      float: right;
    }
  }
  .info-btn-wrapper {
    padding-bottom: 20px;
    margin-bottom: 20px;
    border-bottom: 1px solid #f2f2f2;
  }
}
.worker-toolbox {
  margin-top: 32px;
  padding: 32px;
  background-color: $background-color_2;
  padding: 10px;
  h3 {
    display: inline;
  }
  a.add-toolbox-per-worker {
    float: right;
  }
  .ant-table-wrapper {
    margin-top: 32px;
  }
}
.ant-table-cell {
  padding: 10px !important;
}
@media only screen and (max-width: 810px) {
  .button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.4rem;
    button {
      margin: 0 !important;
    }
  }
  @media only screen and (max-width: 600px) {
    .ant-table-cell {
      padding: 5px !important;
    }
  }
}
